<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Pays *"
            label-for="mc-first-name"
          >
            <v-select
              id="pays"
              v-model="selected"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Département / Région *"
            label-for="mc-last-name"
          >
            <v-select
              id="departement"
              v-model="selected"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Ville *"
            label-for="mc-city"
          >
            <v-select
              id="ville"
              v-model="selected"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Adresse *"
            label-for="mc-country"
          >
            <b-form-input
              id="mc-country"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Boite Postale"
            label-for="mc-company"
          >
            <div class="form-label-group">
              <b-form-input
                id="mc-company"
              />
            </div>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col
          md="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
          >
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>

</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: { title: 'Ville' },
      option: [{ title: 'Ville' }, { title: 'Cotonou' }, { title: 'Porto Novo' }, { title: 'Ouidah' }],
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
